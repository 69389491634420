import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Usuarios.css';

function Usuarios() {
    const { t } = useTranslation();
    const [usuarios, setUsuarios] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [usuariosPerPage] = useState(10);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUsuarios = async () => {
            const user = JSON.parse(sessionStorage.getItem('usuario'));
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_CONSULTAR_USUARIOS_POR_ESTABLECIMIENTO}/${user.establishment_id_fk}`);
                setUsuarios(response.data);
            } catch (error) {
                console.error('Error fetching usuarios:', error);
            }
        };
        fetchUsuarios();
    }, []);

    const handleAddUser = () => {
        navigate('/dashboard/registrar-usuario');
    };

    const handleUserClick = (usuario) => {
        navigate(`/dashboard/actualizar-usuario/${usuario.user_employee_id}`, { state: { usuario } });
    };

    const handleClick = (event, page) => {
        event.preventDefault();
        setCurrentPage(page);
    };

    const indexOfLastUser = currentPage * usuariosPerPage;
    const indexOfFirstUser = indexOfLastUser - usuariosPerPage;
    const currentUsuarios = usuarios.slice(indexOfFirstUser, indexOfLastUser);

    const renderUsuarios = currentUsuarios.map((usuario, index) => (
        <tr key={index} onClick={() => handleUserClick(usuario)} style={{ cursor: 'pointer' }}>
            <td>
                <img title={t('Profile Image')} src={!usuario.profile_picture_employee ? process.env.REACT_APP_SIN_IMAGEN_PERFIL : usuario.profile_picture_employee} alt={t('Profile')} className='avatarUsuarioEmpleoyee' />
            </td>
            <td>{usuario.username_employee}</td>
            <td>{usuario.email_employee}</td>
            <td>{usuario.position_employee}</td>
        </tr>
    ));

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(usuarios.length / usuariosPerPage); i++) {
        pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map(number => (
        <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
            <a href="#" className="page-link" onClick={(e) => handleClick(e, number)}>{number}</a>
        </li>
    ));

    return (
        <div>
            <h2>{t('Users')}</h2>
            <button onClick={handleAddUser} className="add-user-button">{t('Add User')}</button>
            <table className="users-table">
                <thead>
                    <tr>
                        <th className='thAvatarUsuario'>{t('Avatar')}</th>
                        <th>{t('Username')}</th>
                        <th>{t('Email')}</th>
                        <th>{t('Position')}</th>
                    </tr>
                </thead>
                <tbody>
                    {renderUsuarios}
                </tbody>
            </table>
            <nav>
                <ul className="pagination">
                    {renderPageNumbers}
                </ul>
            </nav>
        </div>
    );
}

export default Usuarios;
// Menu.js
import React from 'react';
import './Menu.css';

function Menu() {
  return (
    <div>
      <h2 className='menuHeader'>Menu</h2>
      <p className='contenidoMenu'>Contenido de Menu.</p>
    </div>
  );
}

export default Menu;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import Publicitario_Blanco1 from '../assets/images/Publicitario_Blanco1.png';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  // Crear referencias al audio para poder reproducirlos
  const loginSound = new Audio('/opening-prinder.mp3');
  const errorSound = new Audio('/error-sound.mp3'); 
  const openingLoginSound = new Audio('/opening-login.mp3');
  const silencioSound = new Audio('/silencio.mp3');

  useEffect(() => {
    // Preload the sound
    loginSound.preload = "auto";
    errorSound.preload = "auto";  // Preload the error sound
    openingLoginSound.preload = "auto";
    silencioSound.preload = "auto";

    // Reproducir automáticamente al cargar la página si previamente se ha interactuado
    if (localStorage.getItem('hasInteracted')) {
      playSound(loginSound);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_LOGIN}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password })
      });

      const data = await response.json();
      console.log(data); // Mostrar la información de la API por consola

      if (response.ok && data.message === "Inicio de sesión exitoso") {
        // Reproducir sonido de inicio de sesión exitoso
        playSound(openingLoginSound);

        // Almacenar el token y los datos del usuario en el sessionStorage
        sessionStorage.setItem('token', data.user.token);
        sessionStorage.setItem('usuario', JSON.stringify(data.user));

        // Redirigir al usuario al dashboard
        navigate('/dashboard');
      } else {
        // Reproducir sonido de error y mostrar alerta
        playSound(errorSound);
        alert(data.message || 'Credenciales inválidas');
      }
    } catch (error) {
      console.error('Error during login:', error);
      playSound(errorSound);
      alert('Ocurrió un error durante el inicio de sesión. Por favor, inténtalo de nuevo.');
      
    }
  };

  // Función para reproducir un sonido
  const playSound = (sound) => {
    sound.play().then(() => {
      localStorage.setItem('hasInteracted', 'true');
    }).catch(error => {
      console.error('Error playing sound:', error);
    });
  };

  return (
    <div className="login-page" onClick={() => playSound(silencioSound)}>
      <form onSubmit={handleLogin} className="login-form-container">
        <img src={Publicitario_Blanco1} className="login-logo-image" alt="Logo Prinder" />
        <div className="login-welcome-back">
            Bienvenido de nuevo
        </div>
        <div className="login-access-request">
            ¿Primera vez aquí? <span className="login-highlight"><a className="loginSolAccesoLink" href="#">Solicitar acceso a Prinder negocios</a></span>
        </div>
        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} className="login-input-field" required />
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="login-input-field" required />
        <div className="login-terms">
          <div className="login-small-text">Usted reconoce que ha leído y acepta nuestras.</div>
          <div className="login-small-text login-bold">Términos de servicio y nuestra Política de privacidad.</div>
        </div>
        <input type="submit" className="login-submit-button" />
      </form>
    </div>
  );
}

export default Login;
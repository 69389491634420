// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Tablero from './pages/Tablero';
import Reserva from './pages/Reserva';
import Mensajes from './pages/Mensajes';
import DetalleReserva from './pages/DetalleReserva';
import Chat from './pages/Chat';
import Configuracion from './pages/Configuracion';
import RegistrarUsuario from './pages/RegistrarUsuario';
import Menu from './pages/Menu';
import Grupos from './pages/Grupos';
import Promo from './pages/Promo';
import Reportes from './pages/Reportes';
import Finanzas from './pages/Finanzas';
import Eventos from './pages/Eventos';
import DetalleEvento from './pages/DetalleEvento';
import RegistrarEvento from './pages/RegistrarEvento';
import ActualizarUsuario from './pages/ActualizarUsuario';
import './App.css';

function App() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    useEffect(() => {
        // Registra el Service Worker y agrega listener para mensajes
        if ('serviceWorker' in navigator && 'PushManager' in window) {
            navigator.serviceWorker.register('/service-worker.js')
                .then(function (swReg) {
                    console.log('Service Worker is registered', swReg);

                    // Agregar listener para los mensajes del Service Worker
                    navigator.serviceWorker.addEventListener('message', event => {
                        if (event.data && event.data.type === 'playSound') {
                            const audio = new Audio('/notification-sound.mp3'); // Asegúrate de tener la ruta correcta
                            audio.play().catch(err => console.error('Error al reproducir el sonido:', err));
                        }
                    });
                })
                .catch(function (error) {
                    console.error('Service Worker Error', error);
                });
        } else {
            console.warn('Push messaging is not supported');
        }
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/dashboard" element={<DashboardLayout toggleSidebar={setIsSidebarOpen} isSidebarOpen={isSidebarOpen} />}>
                    <Route index element={<Dashboard />} />
                    <Route path="tablero" element={<Tablero />} />
                    <Route path="reserva" element={<Reserva />} />
                    <Route path="reserva/detalle/:id" element={<DetalleReserva />} />
                    <Route path="mensajes" element={<Mensajes />} />
                    <Route path="mensajes/chat/:receiverId" element={<Chat />} />
                    <Route path="configuracion/*" element={<Configuracion />} />
                    <Route path="registrar-usuario" element={<RegistrarUsuario />} />
                    <Route path="menu" element={<Menu />} />
                    <Route path="grupos" element={<Grupos />} />
                    <Route path="promo" element={<Promo />} />
                    <Route path="reportes" element={<Reportes />} />
                    <Route path="finanzas" element={<Finanzas />} />
                    <Route path="eventos" element={<Eventos />} />
                    <Route path="eventos/detalle/:id" element={<DetalleEvento />} />
                    <Route path="eventos/registrar" element={<RegistrarEvento />} />
                    <Route path="eventos/registrar/:id" element={<RegistrarEvento />} />
                    <Route path="actualizar-usuario/:id" element={<ActualizarUsuario />} />
                </Route>
            </Routes>
        </Router>
    );
}

function DashboardLayout({ toggleSidebar, isSidebarOpen }) {
    return (
        <div className="dashboard-layout">
            <Header toggleSidebar={toggleSidebar} />
            <div className="app-container">
                <Sidebar isOpen={isSidebarOpen} />
                <div className="content-principal">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default App;
// Promo.js
import React from 'react';
import './Promo.css'

function Promo() {
  return (
    <div className='contenedorPromo'>
      <h2>Promo</h2>
      <p>Contenido de Promo.</p>
    </div>
  );
}

export default Promo;
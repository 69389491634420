import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './InformacionPersonal.css';
import { useTranslation } from 'react-i18next';

function InformacionPersonal() {
  const { t } = useTranslation();
  const [profile, setProfile] = useState(() => {
    const savedProfile = sessionStorage.getItem('usuario');
    return savedProfile ? JSON.parse(savedProfile) : {};
  });

  const profilePicRef = useRef(null);
  const coverPhotoRef = useRef(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      const user = JSON.parse(sessionStorage.getItem('usuario'));
      const userId = user.user_id;

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_GET_USER_PROFILE}/${userId}`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
        });
        setProfile(response.data);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    fetchProfileData();
  }, []);

  const handleImageChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfile(prevProfile => ({
          ...prevProfile,
          [name]: reader.result
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = (ref) => {
    ref.current.click();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile(prevProfile => ({
      ...prevProfile,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = JSON.parse(sessionStorage.getItem('usuario'));
    const userId = user.user_id;

    try {
      const responseProfile = await axios.put(`${process.env.REACT_APP_API_EDITAR_PERFIL}/${userId}`, profile, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      });

      const responseUser = await axios.put(`${process.env.REACT_APP_API_EDITAR_USUARIOS}/${userId}`, profile, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      });

      if (responseProfile.data.message && responseUser.data.message) {
        alert(t('Profile updated successfully'));
        sessionStorage.setItem('usuario', JSON.stringify(profile));
      } else {
        alert(t('Error updating profile'));
      }
    } catch (error) {
      console.error('Error during profile update:', error);
      alert(t('An error occurred while updating the profile.'));
    }
  };

  return (
    <div className="profile-edit-container">
      <form onSubmit={handleSubmit} className="form-content">
        <div className="cover-photo-container" onClick={() => triggerFileInput(coverPhotoRef)}>
          <img src={profile.cover_picture_url || 'https://via.placeholder.com/600x200'} alt={t('Cover Photo')} />
          <input type="file" name="cover_picture_url" ref={coverPhotoRef} onChange={handleImageChange} accept="image/*" style={{ display: 'none' }} />
        </div>
        <div className="profile-photo-container" onClick={() => triggerFileInput(profilePicRef)}>
          <img src={profile.profile_picture || 'https://via.placeholder.com/150'} alt={t('Profile Picture')} />
          <input type="file" name="profile_picture" ref={profilePicRef} onChange={handleImageChange} accept="image/*" style={{ display: 'none' }} />
        </div>
        <div className="form-group">
          <label>{t('Full Name')}</label>
          <input type="text" name="full_name" value={profile.full_name} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>{t('Username')}</label>
          <input type="text" name="username" value={profile.username} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>{t('Biography')}</label>
          <input type="text" name="about_me" value={profile.about_me} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>{t('Location')}</label>
          <input type="text" name="address" value={profile.address} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>{t('Website')}</label>
          <input type="url" name="website" value={profile.website} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>{t('Birth Date')}</label>
          <input type="date" name="birthdate" value={profile.birthdate} onChange={handleChange} />
        </div>
        <button type="submit">{t('Save Changes')}</button>
      </form>
    </div>
  );
}

export default InformacionPersonal;
// MetodosDePago.js
import React from 'react';

function MetodosDePago() {
  return (
    <div>
      <h2>MetodosDePago</h2>
      <p>Contenido de la pestaña de Información Personal.</p>
    </div>
  );
}

export default MetodosDePago;
import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { useTable, usePagination, useFilters } from 'react-table';
import { useNavigate } from 'react-router-dom';
import './Reserva.css';
import loadingAnimacion from '../assets/images/loading.gif';
import { useTranslation } from 'react-i18next';

function Reserva() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Clases CSS según el estado de la reserva
  const statusClasses = {
    Proxima: 'Proxima',
    Cancelada: 'Cancelada',
    Activa: 'Activa',
    Finalizada: 'Finalizada'
  };

  // Función que se llama al hacer clic en una fila
  const handleRowClick = (reservationId) => {
    navigate(`/dashboard/reserva/detalle/${reservationId}`);
  };

  useEffect(() => {
    const fetchReservas = async () => {
      try {
        const establishmentId = JSON.parse(sessionStorage.getItem('usuario')).establishment_id_fk;
        const response = await axios.get(`${process.env.REACT_APP_API_RESERVAS_POR_ESTABLECIMIENTO}/${establishmentId}`);
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setLoading(false);
      }
    };
    fetchReservas();
  }, []);

  // Define un componente de filtro de texto genérico
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`${t('Search')} ${count} ${t('records')}...`}
      />
    );
  }

  const columns = useMemo(() => [
    {
      Header: t('Status'),
      accessor: 'status_name',
      Filter: DefaultColumnFilter,  // Usa el componente de filtro para esta columna
      Cell: ({ value }) => {
        const correctedValue = value === 'Proxima' ? t('Next') : value;
        return <div className={statusClasses[value]}>{correctedValue}</div>;
      }
    },
    {
      Header: t('Photo'),
      accessor: 'profile_picture_url',
      Cell: ({ value }) => <img src={value} alt="profile" style={{ width: 50, borderRadius: '50%' }} />,
      disableFilters: true  // Deshabilita el filtro para esta columna
    },
    {
      Header: t('Full Name'),
      accessor: 'full_name',
      Filter: DefaultColumnFilter,
    },
    {
      Header: t('Username'),
      accessor: 'username',
      Filter: DefaultColumnFilter,
    },
    {
      Header: t('Date'),
      accessor: 'reservation_date',
      Cell: ({ value }) => new Date(value).toLocaleDateString(),
      Filter: DefaultColumnFilter,
    },
    {
      Header: t('Time'),
      accessor: 'reservation_time',
      Filter: DefaultColumnFilter,
    },
    {
      Header: t('Table'),
      accessor: 'table_number',
      Filter: DefaultColumnFilter,
    },
  ], [t]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      defaultColumn: { Filter: DefaultColumnFilter },
    },
    useFilters,
    usePagination
  );

  if (loading) {
    return <div className='loadingDIV'>
            <img className='animacionLoading' src={loadingAnimacion} alt="loading..." />
          </div>;
  }

  return (
    <div className='reservasContent'>
      <h2>{t('Reservations')}</h2>
      <div className="table-container">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                    {/* Renderiza el componente de filtro aquí */}
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps({
                  onClick: () => handleRowClick(row.original.reservation_id)
                })}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <span>
          {t('Page')}{' '}
          <strong>
            {pageIndex + 1} {t('of')} {pageOptions.length}
          </strong>{' '}
        </span>
      </div>
    </div>
  );
}

export default Reserva;
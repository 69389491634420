// Grupos.js
import React from 'react';
import './Grupos.css'

function Grupos() {
  return (
    <div className='divContenedorGrupos'>
      <h2>Grupos</h2>
      <p>Contenido de Grupos.</p>
    </div>
  );
}

export default Grupos;
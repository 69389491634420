import React, { useState, useEffect } from 'react';
import './Horarios.css';
import { useTranslation } from 'react-i18next';

const Horarios = () => {
  const { t, i18n } = useTranslation();
  const [horarios, setHorarios] = useState([]);
  
  const user = JSON.parse(sessionStorage.getItem('usuario'));
  const establishmentId = user.establishment_id_fk;

  const getDefaultHorarios = () => {
    return [
      t('Monday'), t('Tuesday'), t('Wednesday'), t('Thursday'), t('Friday'), t('Saturday'), t('Sunday')
    ].map(day => ({
      day,
      open: "08:00",
      close: "17:00",
      isActive: false
    }));
  }

  useEffect(() => {
    setHorarios(getDefaultHorarios());
  }, [i18n.language]);

  useEffect(() => {
    const fetchEstablishmentData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_ESTABLECIMIENTO}/${establishmentId}`);
        const data = await response.json();

        if (data.hours) {
          const updatedHorarios = getDefaultHorarios().map(defaultHorario => {
            const horario = data.hours.find(h => t(h.day_of_week) === defaultHorario.day) || {};
            return {
              ...defaultHorario,
              open: horario.open_time ? horario.open_time.slice(0, 5) : "08:00",
              close: horario.close_time ? horario.close_time.slice(0, 5) : "17:00",
              isActive: horario.is_active || false
            };
          });
          setHorarios(updatedHorarios);
        }
      } catch (error) {
        console.error('Error fetching establishment data:', error);
      }
    };

    fetchEstablishmentData();
  }, [establishmentId, i18n.language]);

  const handleTimeChange = (index, field, value) => {
    const newHorarios = [...horarios];
    newHorarios[index][field] = value;
    setHorarios(newHorarios);
  };

  const toggleDayActive = async (index) => {
    const newHorarios = [...horarios];
    newHorarios[index].isActive = !newHorarios[index].isActive;
    setHorarios(newHorarios);

    const updatedHour = {
      day_of_week: newHorarios[index].day,
      open_time: newHorarios[index].open,
      close_time: newHorarios[index].close,
      is_active: newHorarios[index].isActive
    };

    const method = newHorarios[index].isActive ? 'POST' : 'PUT';
    const requestBody = method === 'POST' ? updatedHour : { hours: [updatedHour] };

    console.log('Sending to API:', JSON.stringify(requestBody));

    try {
      const response = await fetch(`${process.env.REACT_APP_API_ESTABLECIMIENTO}/${establishmentId}/hours`, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        console.error('Failed to update establishment hours');
      }
    } catch (error) {
      console.error('Error updating establishment hours:', error);
    }
  };

  return (
    <div className="horarios-container">
      <h2>{t('Schedules')}</h2>
      {horarios.map((horario, index) => (
        <div key={index} className="horarios-day">
          <label>{horario.day}</label>
          <select
            value={horario.open}
            onChange={(e) => handleTimeChange(index, 'open', e.target.value)}
          >
            {Array.from({ length: 24 }, (_, i) => (
              <option key={i} value={`${i < 10 ? '0' + i : i}:00`}>{i < 10 ? '0' + i : i}:00</option>
            ))}
          </select>
          <select
            value={horario.close}
            onChange={(e) => handleTimeChange(index, 'close', e.target.value)}
          >
            {Array.from({ length: 24 }, (_, i) => (
              <option key={i} value={`${i < 10 ? '0' + i : i}:00`}>{i < 10 ? '0' + i : i}:00</option>
            ))}
          </select>
          <label className="switch">
            <input
              type="checkbox"
              checked={horario.isActive}
              onChange={() => toggleDayActive(index)}
            />
            <span className="slider round"></span>
          </label>
        </div>
      ))}
    </div>
  );
}

export default Horarios;
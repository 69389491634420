// src/pages/DetalleEvento.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './DetalleEvento.module.css';
import { useTranslation } from 'react-i18next';

const DetalleEvento = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_EVENTOS}/${id}`);
        setEvent(response.data);
      } catch (error) {
        console.error('Error fetching event:', error);
      }
    };

    fetchEvent();
  }, [id]);

  const handleBackClick = () => {
    navigate('/dashboard/eventos');
  };

  if (!event) {
    return <div>{t('Loading')}...</div>;
  }

  return (
    <div className={styles.detalleEventoContainer}>
      <button onClick={handleBackClick} className={styles.backButton}>{t('Back to Events')}</button>
      <h1 className={styles.eventTitle}>{event.titles}</h1>
      <p className={styles.eventDescription}>{event.description}</p>
      <p className={styles.eventDetails}>{t('Start Date')}: {new Date(event.start_date).toLocaleDateString()}</p>
      <p className={styles.eventDetails}>{t('End Date')}: {new Date(event.end_date).toLocaleDateString()}</p>
      <p className={styles.eventDetails}>{t('Start Time')}: {event.start_time}</p>
      <p className={styles.eventDetails}>{t('End Time')}: {event.end_time}</p>
      <p className={styles.eventDetails}>{t('Place')}: {event.place}</p>
      <p className={styles.eventDetails}>{t('Price')}: ${event.price}</p>
      <p className={styles.eventDetails}>{t('Discount')}: {event.discount}%</p>
      <p className={styles.eventDetails}>{t('Number of Views')}: {event.number_views}</p>
      <p className={styles.eventDetails}>{t('Number of Likes')}: {event.number_likes}</p>
      {event.talentEvents.length > 0 && (
        <div className={styles.talentContainer}>
          <h2>{t('Talents')}:</h2>
          <ul>
            {event.talentEvents.map((talent) => (
              <li key={talent.talent_event_id}>{talent.name}</li>
            ))}
          </ul>
        </div>
      )}
      {event.eventImages.length > 0 && (
        <div className={styles.imagesContainer}>
          <h2>{t('Images')}:</h2>
          {event.eventImages.map((image) => (
            <img key={image.event_image_id} src={image.ref_firestore} alt="Event" className={styles.eventImage} />
          ))}
        </div>
      )}
    </div>
  );
};

export default DetalleEvento;
// Finanzas.js
import React from 'react';

function Finanzas() {
  return (
    <div>
      <h2>Finanzas</h2>
      <p>Contenido de Finanzas.</p>
    </div>
  );
}

export default Finanzas;
// src/utils/statistics.js
export const mockReservations = [
    { reservation_id: 1, user_id_fk: 1, status_id_fk: 1, amount_people: 2, reservation_date: '2023-07-01' },
    { reservation_id: 2, user_id_fk: 2, status_id_fk: 2, amount_people: 4, reservation_date: '2023-07-02' },
    { reservation_id: 3, user_id_fk: 3, status_id_fk: 1, amount_people: 3, reservation_date: '2023-07-03' },
    { reservation_id: 4, user_id_fk: 4, status_id_fk: 2, amount_people: 5, reservation_date: '2023-07-04' },
    { reservation_id: 5, user_id_fk: 5, status_id_fk: 1, amount_people: 1, reservation_date: '2023-07-05' },
    { reservation_id: 6, user_id_fk: 6, status_id_fk: 1, amount_people: 2, reservation_date: '2023-07-06' },
    { reservation_id: 7, user_id_fk: 7, status_id_fk: 1, amount_people: 2, reservation_date: '2023-07-07' },
    { reservation_id: 8, user_id_fk: 8, status_id_fk: 2, amount_people: 4, reservation_date: '2023-07-08' },
    { reservation_id: 9, user_id_fk: 9, status_id_fk: 1, amount_people: 3, reservation_date: '2023-07-09' },
    { reservation_id: 10, user_id_fk: 10, status_id_fk: 2, amount_people: 5, reservation_date: '2023-07-10' },
    { reservation_id: 11, user_id_fk: 11, status_id_fk: 1, amount_people: 1, reservation_date: '2023-07-11' },
    { reservation_id: 12, user_id_fk: 12, status_id_fk: 1, amount_people: 2, reservation_date: '2023-07-12' },
    { reservation_id: 13, user_id_fk: 13, status_id_fk: 1, amount_people: 2, reservation_date: '2023-07-13' },
    { reservation_id: 14, user_id_fk: 14, status_id_fk: 2, amount_people: 4, reservation_date: '2023-07-14' },
    { reservation_id: 15, user_id_fk: 15, status_id_fk: 1, amount_people: 3, reservation_date: '2023-07-15' },
    { reservation_id: 16, user_id_fk: 16, status_id_fk: 2, amount_people: 5, reservation_date: '2023-07-16' },
    { reservation_id: 17, user_id_fk: 17, status_id_fk: 1, amount_people: 1, reservation_date: '2023-07-17' },
    { reservation_id: 18, user_id_fk: 18, status_id_fk: 1, amount_people: 2, reservation_date: '2023-07-18' },
    { reservation_id: 19, user_id_fk: 19, status_id_fk: 1, amount_people: 2, reservation_date: '2023-07-19' },
    { reservation_id: 20, user_id_fk: 20, status_id_fk: 2, amount_people: 4, reservation_date: '2023-07-20' },
    { reservation_id: 21, user_id_fk: 21, status_id_fk: 1, amount_people: 3, reservation_date: '2023-07-21' },
    { reservation_id: 22, user_id_fk: 22, status_id_fk: 2, amount_people: 5, reservation_date: '2023-07-22' },
    { reservation_id: 23, user_id_fk: 23, status_id_fk: 1, amount_people: 1, reservation_date: '2023-07-23' },
    { reservation_id: 24, user_id_fk: 24, status_id_fk: 1, amount_people: 2, reservation_date: '2023-07-24' },
    { reservation_id: 25, user_id_fk: 25, status_id_fk: 1, amount_people: 2, reservation_date: '2023-07-25' },
    { reservation_id: 26, user_id_fk: 26, status_id_fk: 2, amount_people: 4, reservation_date: '2023-07-26' },
    { reservation_id: 27, user_id_fk: 27, status_id_fk: 1, amount_people: 3, reservation_date: '2023-07-27' },
    { reservation_id: 28, user_id_fk: 28, status_id_fk: 2, amount_people: 5, reservation_date: '2023-07-28' },
    { reservation_id: 29, user_id_fk: 29, status_id_fk: 1, amount_people: 1, reservation_date: '2023-07-29' },
    { reservation_id: 30, user_id_fk: 30, status_id_fk: 1, amount_people: 2, reservation_date: '2023-07-30' },
    { reservation_id: 31, user_id_fk: 31, status_id_fk: 1, amount_people: 2, reservation_date: '2023-07-31' },
];

export const mockReservationStatuses = [
    { status_id: 1, status_name: 'Confirmed' },
    { status_id: 2, status_name: 'Cancelled' },
    { status_id: 3, status_name: 'Pending' },
];

export const mockPurchases = [
    { purchase_id: 1, amount: 100, status_id_fk: 1, purchase_date: '2023-07-01' },
    { purchase_id: 2, amount: 150, status_id_fk: 2, purchase_date: '2023-07-02' },
    { purchase_id: 3, amount: 200, status_id_fk: 1, purchase_date: '2023-07-03' },
    { purchase_id: 4, amount: 250, status_id_fk: 3, purchase_date: '2023-07-04' },
    { purchase_id: 5, amount: 300, status_id_fk: 1, purchase_date: '2023-07-05' },
    { purchase_id: 6, amount: 350, status_id_fk: 2, purchase_date: '2023-07-06' },
    { purchase_id: 7, amount: 400, status_id_fk: 1, purchase_date: '2023-07-07' },
    { purchase_id: 8, amount: 450, status_id_fk: 3, purchase_date: '2023-07-08' },
    { purchase_id: 9, amount: 500, status_id_fk: 1, purchase_date: '2023-07-09' },
    { purchase_id: 10, amount: 550, status_id_fk: 2, purchase_date: '2023-07-10' },
    { purchase_id: 11, amount: 600, status_id_fk: 1, purchase_date: '2023-07-11' },
    { purchase_id: 12, amount: 650, status_id_fk: 3, purchase_date: '2023-07-12' },
    { purchase_id: 13, amount: 700, status_id_fk: 1, purchase_date: '2023-07-13' },
    { purchase_id: 14, amount: 750, status_id_fk: 2, purchase_date: '2023-07-14' },
    { purchase_id: 15, amount: 800, status_id_fk: 1, purchase_date: '2023-07-15' },
    { purchase_id: 16, amount: 850, status_id_fk: 3, purchase_date: '2023-07-16' },
    { purchase_id: 17, amount: 900, status_id_fk: 1, purchase_date: '2023-07-17' },
    { purchase_id: 18, amount: 950, status_id_fk: 2, purchase_date: '2023-07-18' },
    { purchase_id: 19, amount: 1000, status_id_fk: 1, purchase_date: '2023-07-19' },
    { purchase_id: 20, amount: 1050, status_id_fk: 3, purchase_date: '2023-07-20' },
    { purchase_id: 21, amount: 1100, status_id_fk: 1, purchase_date: '2023-07-21' },
    { purchase_id: 22, amount: 1150, status_id_fk: 2, purchase_date: '2023-07-22' },
    { purchase_id: 23, amount: 1200, status_id_fk: 1, purchase_date: '2023-07-23' },
    { purchase_id: 24, amount: 1250, status_id_fk: 3, purchase_date: '2023-07-24' },
    { purchase_id: 25, amount: 1300, status_id_fk: 1, purchase_date: '2023-07-25' },
    { purchase_id: 26, amount: 1350, status_id_fk: 2, purchase_date: '2023-07-26' },
    { purchase_id: 27, amount: 1400, status_id_fk: 1, purchase_date: '2023-07-27' },
    { purchase_id: 28, amount: 1450, status_id_fk: 3, purchase_date: '2023-07-28' },
    { purchase_id: 29, amount: 1500, status_id_fk: 1, purchase_date: '2023-07-29' },
    { purchase_id: 30, amount: 1550, status_id_fk: 2, purchase_date: '2023-07-30' },
];

export const mockPurchaseStatuses = [
    { status_id: 1, status_name: 'Completed' },
    { status_id: 2, status_name: 'Pending' },
    { status_id: 3, status_name: 'Cancelled' },
];

export const getReservationCountByStatus = (reservations, statuses) => {
    return statuses.map(status => ({
        status: status.status_name,
        count: reservations.filter(reservation => reservation.status_id_fk === status.status_id).length,
    }));
};

export const getTotalRevenue = (purchases) => {
    return purchases.reduce((total, purchase) => total + purchase.amount, 0);
};

export const getPurchasesByStatus = (purchases, statuses) => {
    return statuses.map(status => ({
        status: status.status_name,
        count: purchases.filter(purchase => purchase.status_id_fk === status.status_id).length,
    }));
};

export const getReservationsByDay = (reservations) => {
    const reservationsByDay = reservations.reduce((acc, reservation) => {
        const day = reservation.reservation_date;
        if (!acc[day]) {
            acc[day] = 0;
        }
        acc[day]++;
        return acc;
    }, {});

    return Object.entries(reservationsByDay).map(([day, count]) => ({ day, count }));
};
import React, { useState } from 'react';
import axios from 'axios';
import './Seguridad.css';
import { useTranslation } from 'react-i18next';

function Seguridad() {
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setMessage(t('passwords_do_not_match'));
      return;
    }

    const user = JSON.parse(sessionStorage.getItem('usuario'));
    const userId = user.user_id;

    try {
      const response = await axios.put(`${process.env.REACT_APP_API_UPDATE_PASS_USUARIO}/${userId}`, {
        oldPassword,
        newPassword,
      }, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
      });

      setMessage(response.data.message || t('password_updated_successfully'));
    } catch (error) {
      setMessage(error.response?.data?.message || t('error_updating_password'));
    }
  };

  return (
    <div className="seguridad-container">
      <h2>{t('security')}</h2>
      <form onSubmit={handleChangePassword} className="seguridad-form">
        <div className="form-group">
          <label>{t('current_password')}:</label>
          <input
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>{t('new_password')}:</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>{t('confirm_new_password')}:</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="submit-button">{t('change_password')}</button>
      </form>
      {message && <p className="message">{message}</p>}
    </div>
  );
}

export default Seguridad;
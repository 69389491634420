import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Sidebar.css';
import { useTranslation } from 'react-i18next';

import tableroIcon from '../assets/icons/tablero.png';
import reservaIcon from '../assets/icons/reserva.png';
import mensajesIcon from '../assets/icons/mensajes.png';
import menuIcon from '../assets/icons/menu.png';
import gruposIcon from '../assets/icons/grupos.png';
import promoIcon from '../assets/icons/promo.png';
import reportesIcon from '../assets/icons/reportes.png';
import finanzasIcon from '../assets/icons/finanzas.png';
import eventosIcon from '../assets/icons/eventos.png';
import configuracionIcon from '../assets/icons/configuracion.png';

function Sidebar({ isOpen }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            const user = JSON.parse(sessionStorage.getItem('usuario'));
            const token = sessionStorage.getItem('token');

            if (!user || !token) {
                sessionStorage.clear();
                navigate('/');
                return;
            }

            const response = await axios.post(`${process.env.REACT_APP_API_LOGOUT}`, {
                userId: user.user_id
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.data.message === t("Logout successful")) {
                sessionStorage.clear();
                navigate('/');
            } else {
                console.error(`${t('Failed to logout properly')}:`, response.data.message);
                alert(t('Error logging out. Please try again.'));
            }
        } catch (error) {
            console.error(`${t('An error occurred during logout. Please try again.')}:`, error);
            alert(t('An error occurred during logout. Please try again.'));
        }
    };

    return (
        <div className={`sidebar ${isOpen ? 'open' : ''}`}>
            <nav>
                <ul>
                    <li><Link to="/dashboard/tablero"><img src={tableroIcon} alt="Tablero" className="icon" /> {t('Dashboard')}</Link></li>
                    <li><Link to="/dashboard/reserva"><img src={reservaIcon} alt="Reserva" className="icon" /> {t('Reservation')}</Link></li>
                    <li><Link to="/dashboard/mensajes"><img src={mensajesIcon} alt="Mensajes" className="icon" /> {t('Messages')}</Link></li>
                    <li><Link to="/dashboard/menu"><img src={menuIcon} alt="Menú" className="icon" /> {t('Menu')}</Link></li>
                    <li><Link to="/dashboard/grupos"><img src={gruposIcon} alt="Grupos" className="icon" /> {t('Groups')}</Link></li>
                    <li><Link to="/dashboard/promo"><img src={promoIcon} alt="Promo" className="icon" /> {t('Promo')}</Link></li>
                    <li><Link to="/dashboard/reportes"><img src={reportesIcon} alt="Reportes" className="icon" /> {t('Reports')}</Link></li>
                    <li><Link to="/dashboard/finanzas"><img src={finanzasIcon} alt="Finanzas" className="icon" /> {t('Finance')}</Link></li>
                    <li><Link to="/dashboard/eventos"><img src={eventosIcon} alt="Eventos" className="icon" /> {t('Events')}</Link></li>
                    <li><Link to="/dashboard/configuracion/informacion-personal"><img src={configuracionIcon} alt="Configuración" className="icon" /> {t('Settings')}</Link></li>
                </ul>
            </nav>
        </div>
    );
}

export default Sidebar;
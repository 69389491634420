import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './Chat.css';
import Picker from 'emoji-picker-react';
import { useTranslation } from 'react-i18next';

function Chat() {
    const { receiverId } = useParams();
    const { t } = useTranslation();
    const [mensajes, setMensajes] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [receiverName, setReceiverName] = useState('');
    const [senderUsername, setSenderUsername] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const messagesEndRef = useRef(null);

    useEffect(() => {
        const fetchMensajes = async () => {
            const establishmentId = JSON.parse(sessionStorage.getItem('usuario')).establishment_id_fk;
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_CHAT_COMPLETO}?establishmentId=${establishmentId}&userId=${receiverId}`);
                setMensajes(response.data);
                if (response.data.length > 0) {
                    setReceiverName(response.data[0].receiver_username);
                    setSenderUsername(response.data[0].sender_username);
                }
            } catch (error) {
                console.error('Error fetching mensajes:', error);
            }
        };
        fetchMensajes();
    }, [receiverId]);

    useEffect(() => {
        scrollToBottom();
    }, [mensajes]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const onEmojiClick = (emojiObject, event) => {
        setNewMessage(prevInput => prevInput + emojiObject.emoji);
        setShowEmojiPicker(false);  // Cierra el modal al seleccionar un emoji
    };

    const handleEmojiPickerVisibility = () => {
        setShowEmojiPicker(prevState => !prevState);
    };

    useEffect(() => {
        if (showEmojiPicker) {
            scrollToBottom();
        }
    }, [showEmojiPicker]);

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage.trim()) {
            alert(t('EmptyMessageAlert'));
            return;
        }
        const userInfo = JSON.parse(sessionStorage.getItem('usuario'));
        const senderId = userInfo.establishment_id_fk;
        const profilePictureUrl = userInfo.profile_picture_url; // Suponiendo que esto está en la sesión
    
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_ENVIAR_MENSAJE}`, {
                senderId,
                receiverId,
                message: newMessage.trim()
            });
            if (response.data) {
                const newMsg = {
                    ...response.data,
                    sender_username: receiverName,
                    message: newMessage.trim(),
                    profile_picture_url: profilePictureUrl
                };
                setMensajes(prevMensajes => [...prevMensajes, newMsg]);
                setNewMessage('');
            }
        } catch (error) {
            console.error('Error sending message:', error);
            alert(t('SendMessageError'));
        }
    };

    return (
        <div className="chat-chat-container">
            <h2 className='headerH2Chat'>{t('ChatWith')} @{senderUsername}</h2>
            <div className="chat-messages-container">
                {mensajes.map((msg, index) => (
                    <div key={index} className={`chat-message-item ${msg.sender_id === parseInt(receiverId, 10) ? 'chat-received' : 'chat-sent'}`}>
                        <div className="chat-message-header">
                            <img src={msg.profile_picture_url || 'default-avatar.png'} alt={msg.sender_username} />
                            <div>
                                <h5>@{msg.sender_username} <span className={`${msg.sender_id === parseInt(receiverId, 10) ? 'chat-fechaMensajeReceived' : 'chat-fechaMensajeSent'}`}> - {new Date(msg.timestamp).toLocaleTimeString()}</span></h5>
                            </div>
                        </div>
                        {msg.message}
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>
            <form onSubmit={handleSendMessage} className="chat-send-message-form">
                <button className='btnChatEmojiPicker' type="button" onClick={handleEmojiPickerVisibility}>😊</button>
                {showEmojiPicker && (
                    <>
                        <div className="emoji-picker-backdrop" onClick={() => setShowEmojiPicker(false)}></div>
                        <div className="emoji-picker-modal">
                            <Picker onEmojiClick={onEmojiClick} />
                        </div>
                    </>
                )}
                <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder={t('WriteMessage')}
                    autoFocus
                />
                <button className='btnChatEnviar' type="submit" disabled={!newMessage.trim()}>{t('Send')}</button>
            </form>
        </div>
    );
}

export default Chat;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-modal';
import './Header.css';
import qrImage from '../assets/images/qr.gif';
import notificacionesImage from '../assets/images/notificaciones.png';
import notificacionesNuevasImage from '../assets/images/notificaciones_nuevas.gif';
import logo from '../assets/images/logo.png';
import icoLogout from '../assets/images/logout.gif';
import AnimacionSinPrinderNotificaciones from '../assets/images/Animacion-Sin-Prinder-Notificaciones.gif';
import { useTranslation } from 'react-i18next';

// Importa las imágenes de las banderas
import flagES from '../assets/flags/es.png';
import flagEN from '../assets/flags/en.png';
import flagIT from '../assets/flags/it.png';
import flagPT from '../assets/flags/pt.png';
import flagZH from '../assets/flags/zh.png';

function Header({ toggleSidebar }) {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isNotificationsModalOpen, setIsNotificationsModalOpen] = useState(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      fetchNotifications();
    }, 10000); // Realiza el sondeo cada 10 segundos

    return () => clearInterval(interval);
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const fetchNotifications = async () => {
    const user = JSON.parse(sessionStorage.getItem('usuario'));
    if (!user || !user.user_id) return;

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL_NOTIFICACIONES}/notifications/unread/${user.user_id}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
      });
      localStorage.setItem('notifications', JSON.stringify(response.data));
      setNotifications(response.data);
      setUnreadCount(response.data.filter(n => !n.isRead).length);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const handleNotificationClick = async (notification) => {
    setSelectedNotification(notification);
    setIsDetailModalOpen(true);
    if (!notification.isRead) {
      await axios.patch(`${process.env.REACT_APP_API_URL_NOTIFICACIONES}/notifications/read/${notification.id}`, {}, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
      });
      fetchNotifications(); // Refrescar las notificaciones después de marcar como leída
    }
  };

  const handleLogout = async () => {
    try {
      const user = JSON.parse(sessionStorage.getItem('usuario'));
      const token = sessionStorage.getItem('token');

      if (!user || !token) {
        sessionStorage.clear();
        navigate('/');
        return;
      }

      const response = await axios.post(`${process.env.REACT_APP_API_LOGOUT}`, {
        userId: user.user_id
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.data.message === "Sesión cerrada con éxito") {
        sessionStorage.clear();
        navigate('/');
      } else {
        console.error('No se pudo cerrar la sesión correctamente:', response.data.message);
        alert('Error al cerrar sesión. Por favor, inténtalo de nuevo.');
      }
    } catch (error) {
      console.error('Error durante el logout:', error);
      alert('Ocurrió un error durante el logout. Por favor, inténtalo de nuevo.');
    }
  };

  const languages = [
    { code: 'es', name: 'ES', flag: flagES },
    { code: 'en', name: 'EN', flag: flagEN },
    { code: 'it', name: 'IT', flag: flagIT },
    { code: 'pt', name: 'PT', flag: flagPT },
    { code: 'zh', name: 'ZH', flag: flagZH }
  ];

  return (
    <header className="header-prinder">
      <div className="logo">
        <img className="publicitario-blanco-1" src={logo} alt="Logo" onClick={() => navigate('/')} />
      </div>
      <div className="acciones">
        <div id="leerCodigoQR" className="icon-qr" title="Escanear código QR">
          <img className="group" src={qrImage} alt="Código QR" />
        </div>
        <div className="icon-notification" title="Ver notificaciones" onClick={() => setIsNotificationsModalOpen(true)}>
          {
            unreadCount > 0 ? <img className="group-1592-Nuevas" src={notificacionesNuevasImage} alt="Notificaciones" /> :
              <img className="group-1592" src={notificacionesImage} alt="Notificaciones" />
          }
          {unreadCount > 0 && <span id="notification-count" className="badge">{unreadCount}</span>}
        </div>
        <div className="language-selector">
          <select className='lenguajeSelectorHeader transparente' title={t('selector_idioma')} onChange={(e) => changeLanguage(e.target.value)}>
            {languages.map((lang) => (
              <option key={lang.code} value={lang.code}>
                <div className="option-content">
                  <img src={lang.flag} alt={lang.name} className="flag-icon" />
                  {lang.name}
                </div>
              </option>
            ))}
          </select>
        </div>
        <div className="icon-notification" onClick={handleLogout} title="Cerrar sesión">
          <img className="logout-group-1592" src={icoLogout} alt="Cerrar Sesión" />
        </div>
      </div>
      {/* Modal for displaying notifications */}
      <Modal
        isOpen={isNotificationsModalOpen}
        onRequestClose={() => setIsNotificationsModalOpen(false)}
        overlayClassName="header-notification-overlay"
        className="header-notification-content"
        contentLabel="Notificaciones"
      >
        <div className="header-notification-header">Notificaciones</div>
        {notifications.length > 0 ? (
          <ul className="header-notification-list">
            {notifications.map((notification, index) => (
              <li key={index} onClick={() => handleNotificationClick(notification)}>
                <strong>{notification.title}</strong><br />
                {notification.message.substring(0, 100)}...<br />
                {new Date(notification.createdAt).toLocaleDateString()}
              </li>
            ))}
          </ul>
        ) : (
          <div className="header-notification-empty">
            <img className='animacionSinPrinderNotificaciones' src={AnimacionSinPrinderNotificaciones} alt="No existen notificaciones por ahora." />
            <br />
            No existen notificaciones por ahora.
          </div>
        )}
      </Modal>
      {/* Modal for displaying detailed notification */}
      <Modal
        isOpen={isDetailModalOpen}
        onRequestClose={() => setIsDetailModalOpen(false)}
        overlayClassName="header-notification-overlay"
        className="header-notification-content"
        contentLabel="Detalle de Notificación"
      >
        <div className="header-notification-header">Detalle de Notificación</div>
        {selectedNotification && (
          <div className="header-notification-details">
            <p>{selectedNotification.message}</p>
            <button className="header-notification-close-btn" onClick={() => setIsDetailModalOpen(false)}>Cerrar</button>
          </div>
        )}
      </Modal>
    </header>
  );
}

export default Header;
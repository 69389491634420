import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-modal';
import './DetalleReserva.css';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';

// Estilos para el modal
Modal.setAppElement('#root');

function DetalleReserva() {
    const { t } = useTranslation();
    const { id } = useParams();
    const [reserva, setReserva] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [countdown, setCountdown] = useState('');

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [cancelReason, setCancelReason] = useState('');

    const navigate = useNavigate();

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    const fetchReserva = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_DETALLE_RESERVA}/${id}`);
            if (response.data) {
                setReserva(response.data);
                if (response.data.status_id_fk === 9) {
                    const targetDate = new Date(response.data.reservation_date + 'T' + response.data.reservation_time);
                    startCountdown(targetDate);
                }
            }
            setLoading(false);
        } catch (error) {
            setError(t('Error loading reservation details'));
            setLoading(false);
        }
    };

    const updateReservationStatus = async (statusId, establishmentUserId, cancelationDescrip) => {
        try {
            const url = `${process.env.REACT_APP_API_ACTUALIZAR_RESERVA}/${id}`;
            const body = {
                statusId: statusId,
                establishmentUserId: establishmentUserId,
                cancelation_descrip: cancelationDescrip || ''
            };
            await axios.put(url, body);
            alert(t('Reservation updated successfully'));
            fetchReserva();  // Vuelve a cargar la reserva para obtener los datos actualizados
        } catch (error) {
            console.error('Error updating reservation:', error);
            alert(t('Error updating reservation'));
        }
    };

    const handleCancelReservation = () => {
        if (!cancelReason.trim()) {
            alert(t('Please enter a reason for the cancellation.'));
            return;
        }
        updateReservationStatus(3, reserva.establishment_user_id_fk, cancelReason);
        closeModal();
    };

    useEffect(() => {
        fetchReserva();
    }, [id]);

    const startCountdown = (targetDate) => {
        console.log('Target Date:', targetDate); // Esto ayudará a ver qué se está generando
        if (isNaN(targetDate.getTime())) {
            console.log('Invalid date object:', targetDate);
            return; // Termina temprano si la fecha es inválida
        }

        const interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = targetDate.getTime() - now;

            if (distance < 0) {
                clearInterval(interval);
                setCountdown(t('Reservation in progress!'));
                return;
            }

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
        }, 1000);

        return () => clearInterval(interval);
    };

    useEffect(() => {
        if (reserva) {
            // Extrae solo la fecha sin el tiempo ni la zona horaria
            const dateOnly = reserva.reservation_date.split('T')[0];
            // Combina la fecha con la hora de `reservation_time`
            const dateString = `${dateOnly}T${reserva.reservation_time}`;
            const targetDate = new Date(dateString);
            console.log('Date String:', dateString); // Esto imprimirá la cadena de fecha que estás intentando convertir
            startCountdown(targetDate);
        }
    }, [reserva]); // Dependencia en 'reserva'

    if (loading) return <p>{t('Loading')}...</p>;
    if (error) return <p>{error}</p>;
    if (!reserva) return <p>{t('No reservation data found.')}</p>;

    return (
        <div className="container mt-5">
            <div className="row rowHeaderDetalleReserva">
                <div className="col">
                    <h1 className="text-center">{t('Reservation Details')}</h1>
                </div>
                <div className="col colVolver">
                    <button id='btnVolver' className="btn btn-primary" onClick={() => window.history.back()}><FaTimes /></button>
                </div>
            </div>

            <div className="contador text-center">
                <div className="frame-2165">
                    <div className="frame-2086">
                        {/* Mostrar temporizador solo si la reserva está próxima y no finalizada o cancelada */}
                        {(reserva.status_id_fk !== 3 && reserva.status_id_fk !== 4 && countdown) ? (
                            <div className="text-center"><strong>{t('Timer')}:</strong> {countdown}</div>
                        ) : (
                            <div className="text-center"><strong>{t('Status')}:</strong> {reserva.status_name}</div>
                        )}
                    </div>
                </div>
                <div id="switchReserva">
                    {reserva.status_id_fk === 9 && (
                        <>
                            <button id='btnActivar' className="btn btn-success" onClick={() => updateReservationStatus(2, reserva.establishment_user_id_fk)}>{t('Activate Reservation')}</button>
                            <button id='btnCancelar' className="btn btn-danger" onClick={openModal}>{t('Cancel Reservation')}</button>
                        </>
                    )}
                    {reserva.status_id_fk === 2 && (
                        <button id='btnFinalizar' className="btn btn-danger" onClick={() => updateReservationStatus(3, reserva.establishment_user_id_fk)}>{t('Finish Reservation')}</button>
                    )}
                </div>
            </div>
            <br />

            <div className="detail-section">
                <div className="tarjetaInfoDetalleReserva">
                    <table className="greyGridTableHead table-responsive">
                        <tbody className="tbodyGreyGridTableHead">
                            <tr>
                                <td align="left">
                                    <img className="local-image-logo" src={reserva.logo} alt={t('Local Logo')} style={{ width: '50px' }} />
                                </td>
                                <td>&nbsp;</td>
                                <td className='detReservaQrImage' align="right">
                                    {reserva.qr_image_path ? <img src={reserva.qr_image_path} alt="QR Code" style={{ width: '50px' }} /> : t('Not available')}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <table className="greyGridTableUserInfo table-responsive">
                        <tbody className='tbodyGreyGridTableHead'>
                            <tr>
                                <td className='detReservaNumberFollowers'>
                                    {reserva.number_followers}
                                    <br />
                                    {reserva.number_followers > 1 ? t('Followers') : t('Follower')}
                                </td>
                                <td className='detReservaProfilePictureUrl'>
                                    <img src={reserva.profile_picture_url} alt={t('User Avatar')} style={{ width: '100px', borderRadius: '50%' }} /> <br />
                                    {reserva.full_name}
                                    <br />
                                    @{reserva.username}
                                </td>
                                <td className='detReservaNumberFollowed'>
                                    {reserva.number_followed}
                                    <br />
                                    {reserva.number_followed > 1 ? t('Followed') : t('Follow')}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <table className="greyGridTable table-responsive">
                        <tbody className='tbodyGridReserva'>
                            <tr>
                                <td className='tdGridReserva'>{t('Place')}:</td>
                                <td align="left" colSpan="2">{reserva.establishment_name}</td>
                            </tr>
                            <tr>
                                <td className='tdGridReserva'>{t('Table')}:</td>
                                <td align="left" colSpan="2">{reserva.quantity}</td>
                            </tr>
                            <tr>
                                <td className='tdGridReserva'>{t('Day')}:</td>
                                <td align="left" colSpan="2">{new Date(reserva.reservation_date).toLocaleDateString()}</td>
                            </tr>
                            <tr>
                                <td className='tdGridReserva'>{t('Time')}:</td>
                                <td align="left" colSpan="2">{reserva.reservation_time}</td>
                            </tr>
                            <tr>
                                <td className='tdGridReserva'>{t('Guests')}:</td>
                                <td align="left" colSpan="2">&nbsp;</td>
                            </tr>
                            <tr>
                                <td className='tdGridReserva'>{t('Products')}:</td>
                                <td align="left" colSpan="2">&nbsp;</td>
                            </tr>
                            <tr>
                                <td className='tdGridReserva'>{t('Important')}:</td>
                                <td align="left" colSpan="2">{t('Reservations are subject to the establishment\'s terms and conditions. Please be aware of the establishment\'s policies regarding reservations.')}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel={t('Inform the reason for your cancellation to the user')}
                className="Modal" // Esta clase aplica los estilos al contenido del modal
                overlayClassName="Overlay" // Esta clase aplica los estilos al fondo oscuro detrás del modal
            >
                <h3>{t('Inform the reason for your cancellation to the user')}</h3>
                <textarea
                    value={cancelReason}
                    onChange={(e) => setCancelReason(e.target.value)}
                    placeholder={t('Enter the reason for the cancellation')}
                />
                <div>
                    <button id="cancelarReserva" onClick={handleCancelReservation}>{t('Confirm Cancellation')}</button>
                    <button onClick={closeModal}>{t('Close')}</button>
                </div>
            </Modal>
        </div>

    );
}

export default DetalleReserva;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import InputMask from 'react-input-mask';
import './RegistrarUsuario.css';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const initialFormData = {
    username_employee: '',
    password_hash_employee: '',
    email_employee: '',
    full_name_employee: '',
    position_employee: '',
    address_employee: '',
    city_employee: '',
    country_employee: '',
    postal_code_employee: '',
    about_me_employee: '',
    telephone_employee: '',
    birthdate_employee: '',
    nationality_employee: '',
    profile_picture_employee: ''
};

const steps = [
    { label: "Datos Personales", fields: ["username_employee", "password_hash_employee", "email_employee", "full_name_employee", "birthdate_employee", "telephone_employee", "profile_picture_employee"] },
    { label: "Dirección", fields: ["address_employee", "city_employee", "country_employee", "postal_code_employee"] },
    { label: "Detalles del Trabajo", fields: ["position_employee", "about_me_employee", "nationality_employee"] },
];

function ActualizarUsuario() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();
    const usuario = location.state ? location.state.usuario : null;

    const [formData, setFormData] = useState(initialFormData);
    const [currentStep, setCurrentStep] = useState(0);
    const [profilePicture, setProfilePicture] = useState(null);
    const [roles, setRoles] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [currentRoles, setCurrentRoles] = useState([]);
    const [birthdateError, setBirthdateError] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (usuario) {
            setFormData({
                username_employee: usuario.username_employee || '',
                password_hash_employee: '',
                email_employee: usuario.email_employee || '',
                full_name_employee: usuario.full_name_employee || '',
                position_employee: usuario.position_employee || '',
                address_employee: usuario.address_employee || '',
                city_employee: usuario.city_employee || '',
                country_employee: usuario.country_employee || '',
                profile_picture_employee: usuario.profile_picture_employee || '',
                postal_code_employee: usuario.postal_code_employee || '',
                about_me_employee: usuario.about_me_employee || '',
                telephone_employee: usuario.telephone_employee || '',
                birthdate_employee: usuario.birthdate_employee ? usuario.birthdate_employee.split('T')[0] : '',
                nationality_employee: usuario.nationality_employee || ''
            });
            fetchUserRoles(id);
        }
    }, [usuario]);

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_GESTION_RBAC_OBTENER_ROLES);
                setRoles(response.data.filter(role => role.role_id !== 1));
            } catch (error) {
                console.error('Error fetching roles:', error);
            }
        };

        fetchRoles();
    }, []);

    const fetchUserRoles = async (userId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_GESTION_RBAC_USER}/${userId}/roles`);
            setSelectedRoles(response.data.map(role => role.role_id));
            setCurrentRoles(response.data);
        } catch (error) {
            console.error('Error fetching user roles:', error);
        }
    };

    const handleRoleChange = (e) => {
        const options = e.target.options;
        const selected = [];
        for (const option of options) {
            if (option.selected) {
                selected.push(option.value);
            }
        }
        setSelectedRoles(selected);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleNext = () => {
        setCurrentStep(prevStep => prevStep + 1);
    };

    const handleBack = () => {
        if (currentStep === 0) {
            navigate('/dashboard/configuracion/usuarios');
        } else {
            setCurrentStep(prevStep => prevStep - 1);
        }
    };

    const handlePictureChange = (e) => {
        setProfilePicture(e.target.files[0]);
    };

    const isValidAge = (birthdate) => {
        const birthDate = new Date(birthdate);
        const today = new Date();
        const age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age >= 18 && age <= 99;
    };

    const handleBirthdateBlur = (e) => {
        const { name, value } = e.target;
        if (!isValidAge(value)) {
            alert(t('valida_edad.'));
            setFormData(prevState => ({
                ...prevState,
                [name]: formData.birthdate_employee
            }));
            setBirthdateError(true);
        } else {
            setBirthdateError(false);
        }
    };

    const handleBirthdateChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (selectedRoles.length === 0) {
            alert(t('valida_rol'));
            return;
        }

        if (isSubmitting) return;  // Prevent multiple submissions
        setIsSubmitting(true);

        const user = JSON.parse(sessionStorage.getItem('usuario'));
        const completeFormData = {
            username_employee: formData.username_employee,
            email_employee: formData.email_employee,
            full_name_employee: formData.full_name_employee,
            position_employee: formData.position_employee,
            address_employee: formData.address_employee,
            city_employee: formData.city_employee,
            country_employee: formData.country_employee,
            postal_code_employee: formData.postal_code_employee,
            about_me_employee: formData.about_me_employee,
            telephone_employee: formData.telephone_employee,
            birthdate_employee: formData.birthdate_employee,
            nationality_employee: formData.nationality_employee,
            establishment_id_fk: user.establishment_id_fk
        };

        if (!isValidAge(completeFormData.birthdate_employee)) {
            alert(t('valida_edad'));
            setBirthdateError(true);
            setIsSubmitting(false);
            return;
        }

        try {
            if (profilePicture) {
                const formData = new FormData();
                formData.append('file', profilePicture);
                formData.append('id', String(id));
                formData.append('name', 'profile_picture');
                formData.append('bucket', 'users_employee');

                const uploadResponse = await axios.post(`${process.env.REACT_APP_MS_IMAGES}/api/v1/image/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                const { img } = uploadResponse.data.data;
                await axios.put(`${process.env.REACT_APP_API_EDITAR_IMAGEN_USUARIO}/${id}`, {
                    profile_picture: img
                });

                completeFormData.profile_picture = img;
            }

            await axios.put(`${process.env.REACT_APP_API_EDITAR_USUARIOS}/${id}`, completeFormData);
            await axios.put(`${process.env.REACT_APP_API_GESTION_RBAC_USER}/${id}/roles`, {
                roleIds: selectedRoles
            });

            alert(completeFormData.username_employee + ' ' + t('actualizacion_exitosa'));
            navigate('/dashboard/configuracion/usuarios');
        } catch (error) {
            console.error('Error actualizando usuario:', error);
            alert(t('error_actualizar_usuario'));
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleRemoveRole = async (roleId) => {
        if (currentRoles.length === 1) {
            alert(t('valida_rol'));
            return;
        }

        try {
            await axios.delete(`${process.env.REACT_APP_API_GESTION_RBAC_USER}/${id}/roles/${roleId}`);
            setSelectedRoles(selectedRoles.filter(r => r !== roleId));
            setCurrentRoles(currentRoles.filter(r => r.role_id !== roleId));
            alert(t('rol_eliminado'));
        } catch (error) {
            console.error('Error eliminando rol:', error);
            alert(t('rol_error_eliminar'));
        }
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleAssignRole = (roleId) => {
        setSelectedRoles([roleId]);
        setCurrentRoles([roles.find(role => role.role_id === roleId)]);
        closeModal();
    };

    const fieldLabels = {
        username_employee: t('Username'),
        password_hash_employee: t('Password'),
        email_employee: t('Email'),
        full_name_employee: t('Full Name'),
        birthdate_employee: t('Birthdate'),
        telephone_employee: t('Telephone'),
        profile_picture_employee: t('Profile Picture'),
        address_employee: t('Address'),
        city_employee: t('City'),
        country_employee: t('Country'),
        postal_code_employee: t('Postal Code'),
        position_employee: t('Position'),
        about_me_employee: t('About Me'),
        nationality_employee: t('Nationality')
    };


    return (
        <div className="registro-usuario-container">
            <div className="header-container">
                <h2 className="header-title">{t('header_actualizar')}</h2>
                <button className="closeButtonActualizar" onClick={() => navigate('/dashboard/configuracion/usuarios')}>
                    <FaTimes />
                </button>
            </div>

            <form className="registro-usuario-form-content">
                {steps[currentStep].fields.map((key) => (
                    <div key={key} className="registro-usuario-form-group">
                        <label>{t(fieldLabels[key] || key.replace(/_/g, ' ').replace('employee', ''))}:</label>
                        {key === 'birthdate_employee' ? (
                            <input
                                type="date"
                                name={key}
                                value={formData[key]}
                                onChange={handleBirthdateChange}
                                onBlur={handleBirthdateBlur}
                                className={birthdateError ? 'input-error' : ''}
                                required
                            />
                        ) : key === 'telephone_employee' ? (
                            <InputMask
                                mask="+(99) - 9999999999"
                                maskChar=" "
                                value={formData[key]}
                                onChange={handleChange}
                            >
                                {(inputProps) => <input {...inputProps} name={key} required />}
                            </InputMask>
                        ) : key === 'profile_picture_employee' ? (
                            <div>
                                <img src={formData.profile_picture_employee || 'default_profile_picture_url'} alt="profile" className='avatarUsuarioEmployee' />
                                <input
                                    type="file"
                                    onChange={handlePictureChange}
                                />
                            </div>
                        ) : (
                            <input
                                type={key.includes('password') ? 'password' : key.includes('email') ? 'email' : key.includes('birthdate') ? 'date' : 'text'}
                                name={key}
                                value={formData[key]}
                                onChange={handleChange}
                                required={key !== 'password_hash_employee'}
                            />
                        )}
                    </div>
                ))}
                {currentStep === 2 && (
                    <div className="registro-usuario-form-group">
                        <label>{t('current_role')}:</label>
                        <div>
                            {currentRoles.map(role => (
                                <div key={role.role_id}>
                                    <span>{role.role_name}</span>
                                    {currentRoles.length > 1 && (
                                        <button className='btnEliminarRol' type="button" onClick={() => handleRemoveRole(role.role_id)}>{t('Eliminar')}</button>
                                    )}
                                </div>
                            ))}
                        </div>
                        <button type="button" onClick={openModal}>{t('cambiar_rol')}</button>
                    </div>
                )}
                <div className="registro-usuario-buttons">
                    <button type="button" onClick={handleBack} className="registro-usuario-button-back">{t('Volver')}</button>
                    {currentStep < steps.length - 1 ? (
                        <button type="button" onClick={handleNext} className="registro-usuario-button">{t('Siguiente')}</button>
                    ) : (
                        <button type="button" onClick={handleSubmit} className="registro-usuario-button">{t('Actualizar')}</button>
                    )}
                </div>
            </form>
            <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="minimalist-modal" overlayClassName="minimalist-overlay">
                <h2>{t('Asignar Rol')}</h2>
                <ul>
                    {roles.filter(role => !selectedRoles.includes(role.role_id)).map(role => (
                        <li key={role.role_id}>
                            <button type="button" onClick={() => handleAssignRole(role.role_id)}>
                                {role.role_name}
                            </button>
                        </li>
                    ))}
                </ul>
                <button className='btnModalRolesCerrar' onClick={closeModal}>{t('Cerrar')}</button>
            </Modal>
        </div>
    );
}

export default ActualizarUsuario;
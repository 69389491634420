import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import './RegistrarUsuario.css';
import { useTranslation } from 'react-i18next';

const initialFormData = {
    username_employee: '',
    password_hash_employee: '',
    email_employee: '',
    full_name_employee: '',
    position_employee: '',
    address_employee: '',
    city_employee: '',
    country_employee: '',
    postal_code_employee: '',
    about_me_employee: '',
    telephone_employee: '',
    birthdate_employee: '',
    nationality_employee: ''
};

function RegistrarUsuario() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [formData, setFormData] = useState(initialFormData);
    const [currentStep, setCurrentStep] = useState(0);
    const [profilePicture, setProfilePicture] = useState(null);
    const [roles, setRoles] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [birthdateError, setBirthdateError] = useState(false);

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_GESTION_RBAC_OBTENER_ROLES);
                const filteredRoles = response.data.filter(role => role.role_id !== 1 && role.role_name !== 'Admin');
                setRoles(filteredRoles);
            } catch (error) {
                console.error('Error fetching roles:', error);
            }
        };

        fetchRoles();
        setFormData(initialFormData);
    }, []);

    const handleRoleChange = (e) => {
        setSelectedRoles([e.target.value]);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleNext = () => {
        setCurrentStep(prevStep => prevStep + 1);
    };

    const handleBack = () => {
        if (currentStep === 0) {
            navigate('/dashboard/configuracion/usuarios');
        } else {
            setCurrentStep(prevStep => prevStep - 1);
        }
    };

    const handlePictureChange = (e) => {
        setProfilePicture(e.target.files[0]);
    };

    const isValidAge = (birthdate) => {
        const birthDate = new Date(birthdate);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age >= 18 && age <= 99;
    };

    const handleBirthdateChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleBirthdateBlur = (e) => {
        const { name, value } = e.target;
        if (!isValidAge(value)) {
            alert(t('User must be between 18 and 99 years old.'));
            setFormData(prevState => ({
                ...prevState,
                [name]: ''
            }));
            setBirthdateError(true);
        } else {
            setBirthdateError(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const user = JSON.parse(sessionStorage.getItem('usuario'));
        const completeFormData = {
            username_employee: formData.username_employee,
            password_hash_employee: formData.password_hash_employee,
            email_employee: formData.email_employee,
            full_name_employee: formData.full_name_employee,
            position_employee: formData.position_employee,
            address_employee: formData.address_employee,
            city_employee: formData.city_employee,
            country_employee: formData.country_employee,
            postal_code_employee: formData.postal_code_employee,
            about_me_employee: formData.about_me_employee,
            telephone_employee: formData.telephone_employee,
            birthdate_employee: formData.birthdate_employee,
            nationality_employee: formData.nationality_employee,
            establishment_id_fk: user.establishment_id_fk
        };

        if (!isValidAge(completeFormData.birthdate_employee)) {
            alert(t('User must have a valid age.'));
            setBirthdateError(true);
            return;
        }

        try {
            const response = await axios.post(process.env.REACT_APP_API_REGISTRAR_USUARIOS, completeFormData);
            const userData = response.data.userData;

            if (userData && userData.user_id) {
                if (profilePicture) {
                    const imageFormData = new FormData();
                    imageFormData.append('file', profilePicture);
                    imageFormData.append('id', String(userData.user_id));
                    imageFormData.append('name', 'profile_picture');
                    imageFormData.append('bucket', 'users_employee');

                    const uploadResponse = await axios.post(`${process.env.REACT_APP_MS_IMAGES}/api/v1/image/upload`, imageFormData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    const { img } = uploadResponse.data.data;
                    await axios.put(`${process.env.REACT_APP_API_EDITAR_IMAGEN_USUARIO}/${userData.user_id}`, {
                        profile_picture: img
                    });

                    alert(`${t('User')} ${userData.username} ${t('registered and profile picture uploaded successfully.')}`);
                } else {
                    alert(`${t('User')} ${userData.username} ${t('registered successfully.')}`);
                }

                // Asignar roles al usuario registrado
                const assignRolesResponse = await axios.post(`${process.env.REACT_APP_API_GESTION_RBAC_USER}/${userData.user_id}/roles`, {
                    roleIds: selectedRoles
                });

                if (assignRolesResponse.status === 201) {
                    alert(`${t('Roles assigned successfully to')} ${userData.username}.`);
                } else {
                    alert(t('Error assigning roles to the user.'));
                }
                navigate('/dashboard/configuracion/usuarios');
            } else {
                throw new Error(t('No valid user ID received'));
            }
        } catch (error) {
            console.error(t('Error registering user:'), error);
            alert(t('Error registering the user'));
        }
    };

    const fieldLabels = {
        username_employee: t("Username"),
        password_hash_employee: t("Password"),
        email_employee: t("Email"),
        full_name_employee: t("Full Name"),
        birthdate_employee: t("Birthdate"),
        telephone_employee: t("Telephone"),
        profile_picture_employee: t("Profile Picture"),
        address_employee: t("Address"),
        city_employee: t("City"),
        country_employee: t("Country"),
        postal_code_employee: t("Postal Code"),
        position_employee: t("Position"),
        about_me_employee: t("About Me"),
        nationality_employee: t("Nationality")
    };

    const steps = [
        { label: t("Personal Data"), fields: ["username_employee", "password_hash_employee", "email_employee", "full_name_employee", "birthdate_employee", "telephone_employee", "profile_picture_employee"] },
        { label: t("Address"), fields: ["address_employee", "city_employee", "country_employee", "postal_code_employee"] },
        { label: t("Job Details"), fields: ["position_employee", "about_me_employee", "nationality_employee"] }
    ];

    return (
        <div className="registro-usuario-container">
            <h2>{t('Register User')}</h2>
            <form onSubmit={handleSubmit} className="registro-usuario-form-content">
                {steps[currentStep].fields.map((key) => (
                    <div key={key} className="registro-usuario-form-group">
                        <label>{fieldLabels[key] || key}:</label>
                        {key === 'birthdate_employee' ? (
                            <input
                                type="date"
                                name={key}
                                value={formData[key]}
                                onChange={handleBirthdateChange}
                                onBlur={handleBirthdateBlur}
                                className={birthdateError ? 'input-error' : ''}
                                required
                            />
                        ) : key === 'telephone_employee' ? (
                            <InputMask
                                mask="+(99) - 9999999999"
                                maskChar=" "
                                value={formData[key]}
                                onChange={handleChange}
                            >
                                {(inputProps) => <input {...inputProps} name={key} required />}
                            </InputMask>
                        ) : key === 'profile_picture_employee' ? (
                            <input
                                type="file"
                                onChange={handlePictureChange}
                            />
                        ) : (
                            <input
                                type={key.includes('password') ? 'password' : key.includes('email') ? 'email' : key.includes('birthdate') ? 'date' : 'text'}
                                name={key}
                                value={formData[key]}
                                onChange={handleChange}
                                required
                            />
                        )}
                    </div>
                ))}
                {currentStep === 2 && (
                    <div className="registro-usuario-form-group">
                        <label>{t('Role')}:</label>
                        <select onChange={handleRoleChange}>
                        <option value="">{t('Select a role')}</option>
                            {roles.map(role => (
                                <option key={role.role_id} value={role.role_id}>{role.role_name}</option>
                            ))}
                        </select>
                    </div>
                )}
                <div className="registro-usuario-buttons">
                    <button type="button" onClick={handleBack} className="registro-usuario-button-back">{t('Back')}</button>
                    {currentStep < steps.length - 1 ? (
                        <button type="button" onClick={handleNext} className="registro-usuario-button">{t('Next')}</button>
                    ) : (
                        <button type="submit" className="registro-usuario-button">{t('Register')}</button>
                    )}
                </div>
            </form>
        </div>
    );
}

export default RegistrarUsuario;
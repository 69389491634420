import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from './Eventos.module.css';
import ChevronLeft from '../assets/images/ChevronLeft.png';
import Search from '../assets/images/search.png';
import { useTranslation } from 'react-i18next';

const Eventos = () => {
  const { t } = useTranslation();
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const establishmentId = JSON.parse(sessionStorage.getItem('usuario')).establishment_id_fk;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_EVENTOS}/establecimiento/${establishmentId}`);
        console.log('Events fetched:', response.data); // Print the response to the console
        setEvents(response.data);
        setFilteredEvents(response.data); // Initially show all events
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, [establishmentId]);

  useEffect(() => {
    const results = events.filter(event =>
      event.titles.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredEvents(results);
  }, [searchTerm, events]);

  const handleEventClick = (id) => {
    navigate(`/dashboard/eventos/registrar/${id}`);
  };

  const handleAddButtonClick = () => {
    navigate('/dashboard/eventos/registrar');
  };

  return (
    <div className={styles.eventosContainer}>
      <div className={styles.eventosHeader}>
        <div className={styles.eventosTitle}>{t('Events')}</div>
        <div className={styles.searchContainer}>
          <div className={styles.searchBox}>
            <div className={styles.searchIcon}>
              <img className={styles.searchVector} src={Search} alt={t('Search')} />
            </div>
            <input
              type="text"
              className={styles.searchInput}
              placeholder={t('Search')}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className={styles.addButton} onClick={handleAddButtonClick}>
            <div className={styles.addIcon}>
              <div className={styles.iconRectangle}></div>
              <div className={styles.iconVerticalLine}></div>
              <div className={styles.iconHorizontalLine}></div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.eventosGrid}>
        {filteredEvents.map((event) => {
          const coverImage = event.eventImages.find(image => image.is_cover);
          return (
            <div key={event.event_id} className={styles.eventosCard} onClick={() => handleEventClick(event.event_id)}>
              <div className={styles.eventosTableView}>
                <div className={styles.eventosSeparator} />
                <div className={styles.eventosCell}>
                  <div className={styles.eventosVFrame}>
                    {coverImage ? (
                      <img className={styles.eventosImage} src={coverImage.ref_firestore} alt={event.titles} />
                    ) : (
                      <img className={styles.eventosImage} src="https://via.placeholder.com/80x120" alt={event.titles} />
                    )}
                    <div className={styles.eventosHFrame}>
                      <div className={styles.eventosTitle}>{event.titles}</div>
                      <div className={styles.eventosDetails}>{event.place}</div>
                      <div className={styles.eventosDetails}>{new Date(event.start_date).toLocaleDateString()}</div>
                      <div className={styles.eventosDetails}>{`${t('Start')}: ${event.start_time} - ${t('End')}: ${event.end_time}`}</div>
                      {event.talentEvents.length > 0 && (
                        <div className={styles.eventosDetails}>
                          {t('Talents')}: {event.talentEvents.map(talent => talent.name).join(', ')}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.eventosIcon}>
                    <div className={styles.eventosChevron}>
                      <div className={styles.eventosVector}>
                        <img src={ChevronLeft} alt="Left" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.eventosSeparator} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Eventos;
// Reportes.js
import React from 'react';
import './Reportes.css'
function Reportes() {
  return (
    <div className='contenedorReportes'>
      <h2>Reportes</h2>
      <p>Contenido de Reportes.</p>
    </div>
  );
}

export default Reportes;
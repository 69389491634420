import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './RegistrarEvento.module.css';
import { FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

const RegistrarEvento = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    titles: '',
    description: '',
    start_date: '',
    end_date: '',
    start_time: '',
    end_time: '',
    place: '',
    price: '',
    discount: '',
    qty_spaces: '',
    link: '',
    fk_origin_id: process.env.REACT_APP_ORIGIN_ID,
    fk_event_type_id: '',
    fk_establishment_id: JSON.parse(sessionStorage.getItem('usuario')).establishment_id_fk,
    status: '',
    images: [],
  });
  const [eventTypes, setEventTypes] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [coverImageIndex, setCoverImageIndex] = useState(0); // Index of the cover image
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);

  const openModal = (image) => {
    setImageToDelete(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setImageToDelete(null);
  };

  useEffect(() => {
    const fetchEventTypes = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_EVENTOS}/tipos-eventos`);
        setEventTypes(response.data);
      } catch (error) {
        console.error('Error fetching event types:', error);
      }
    };

    fetchEventTypes();
  }, []);

  useEffect(() => {
    if (id) {
      const fetchEvent = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_EVENTOS}/${id}`);
          const event = response.data;
          event.start_date = event.start_date.split('T')[0]; // Trim the time part
          event.end_date = event.end_date.split('T')[0]; // Trim the time part
          event.start_time = event.start_time.split('+')[0]; // Trim the timezone part
          event.end_time = event.end_time.split('+')[0]; // Trim the timezone part

          // Fetch images separately
          const imagesResponse = await axios.get(`${process.env.REACT_APP_API_EVENTOS}/${id}/images`);
          event.images = imagesResponse.data;

          setFormData(event);
          setIsEditing(true);
          // Set cover image index based on existing images
          const coverIndex = event.images.findIndex(image => image.is_cover);
          setCoverImageIndex(coverIndex !== -1 ? coverIndex : 0);
        } catch (error) {
          console.error('Error fetching event:', error);
        }
      };

      fetchEvent();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleStatusChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value.toLowerCase(),
    });
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files).slice(0, 5); // Limit to 5 files
    const newImages = files.map(file => ({
      file,
      is_cover: false,
    }));
    setFormData({
      ...formData,
      images: [...formData.images, ...newImages].slice(0, 5), // Ensure only 5 images
    });
  };

  const handleImageUpload = async (eventId) => {
    const uploadPromises = formData.images.map(async (image, index) => {
      if (image.file) {
        const createImageDto = {
          bucket: 'eventos_prinder',
          id: `${eventId}`,
          name: image.fileName,
          file: image.file,
          is_cover: index === coverImageIndex, // Set cover image
        };

        const formDataUpload = new FormData();
        formDataUpload.append('bucket', createImageDto.bucket);
        formDataUpload.append('id', createImageDto.id);
        formDataUpload.append('name', createImageDto.name);
        formDataUpload.append('file', image.file);

        try {
          const response = await axios.post(`${process.env.REACT_APP_MS_IMAGES}/api/v1/image/upload`, formDataUpload, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          return {
            ref_firestore: response.data.data.img,
            is_cover: createImageDto.is_cover,
            fk_event_id: eventId,
            name: response.data.data.fileName,
          };
        } catch (error) {
          console.error('Error uploading image:', error);
          throw error;
        }
      } else {
        // If the image already has a URL, just return it
        return {
          ref_firestore: image.ref_firestore,
          is_cover: image.is_cover,
          fk_event_id: eventId,
          name: image.name,
        };
      }
    });

    return Promise.all(uploadPromises);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const newFormData = {
        ...formData,
        start_date: formData.start_date.split('T')[0], // Trim the time part
        end_date: formData.end_date.split('T')[0], // Trim the time part
        start_time: formData.start_time.split('+')[0], // Trim the timezone part
        end_time: formData.end_time.split('+')[0], // Trim the timezone part
      };
      const response = await axios.post(`${process.env.REACT_APP_API_EVENTOS}`, newFormData);
      const eventId = response.data.event_id;
      const uploadedImages = await handleImageUpload(eventId);
      await axios.post(`${process.env.REACT_APP_API_EVENTOS}/${eventId}/images`, uploadedImages);
      setIsEditing(true);
      setIsLoading(false);
      alert(t('Event created successfully'));
      navigate('/dashboard/eventos');
    } catch (error) {
      setIsLoading(false);
      console.error('Error creating event:', error);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const updatedFormData = {
        ...formData,
        start_date: formData.start_date.split('T')[0], // Trim the time part
        end_date: formData.end_date.split('T')[0], // Trim the time part
        start_time: formData.start_time.split('+')[0], // Trim the timezone part
        end_time: formData.end_time.split('+')[0], // Trim the timezone part
      };
      await axios.put(`${process.env.REACT_APP_API_EVENTOS}/${id}`, updatedFormData);
      const uploadedImages = await handleImageUpload(id);
      await axios.put(`${process.env.REACT_APP_API_EVENTOS}/${id}/images`, uploadedImages);
      setIsLoading(false);
      alert(t('Event updated successfully'));
      navigate('/dashboard/eventos');
    } catch (error) {
      setIsLoading(false);
      console.error('Error updating event:', error);
    }
  };

  const handleDeleteImage = async () => {
    if (imageToDelete) {
      try {
        // Eliminar de la base de datos
        await axios.delete(`${process.env.REACT_APP_API_EVENTOS}/images/${imageToDelete.event_image_id}`);

        // Eliminar del storage
        await axios.request({
          method: 'delete',
          url: `${process.env.REACT_APP_MS_IMAGES}/api/v1/image`,
          data: {
            imgName: imageToDelete.name,
            bucket: 'eventos_prinder',
          },
        });

        // Actualizar el estado del formulario
        const updatedImages = formData.images.filter(image => image.event_image_id !== imageToDelete.event_image_id);
        setFormData({ ...formData, images: updatedImages });

        closeModal();
      } catch (error) {
        console.error('Error deleting image:', error);
      }
    }
  };

  const handleBack = () => {
    navigate('/dashboard/eventos');
  };

  return (
    <div className={styles.registrarEventoContainer}>
      <div className={styles.header}>
        <h1>{isEditing ? t('Update Event') : t('Register Event')}</h1>
        <button onClick={handleBack} className={styles.closeButton}>
          <FaTimes />
        </button>
      </div>
      {isLoading && <div className={styles.loading}>{t('Loading')}...</div>}
      <form onSubmit={isEditing ? handleUpdate : handleSubmit} className={styles.registrarEventoForm}>
        <label>
          {t('Title')}:
          <input type="text" name="titles" value={formData.titles} onChange={handleChange} required />
        </label>
        <label>
          {t('Description')}:
          <textarea name="description" value={formData.description} onChange={handleChange} required />
        </label>
        <label>
          {t('Start Date')}:
          <input type="date" name="start_date" value={formData.start_date} onChange={handleChange} required />
        </label>
        <label>
          {t('End Date')}:
          <input type="date" name="end_date" value={formData.end_date} onChange={handleChange} required />
        </label>
        <label>
          {t('Start Time')}:
          <input type="time" name="start_time" value={formData.start_time} onChange={handleChange} required />
        </label>
        <label>
          {t('End Time')}:
          <input type="time" name="end_time" value={formData.end_time} onChange={handleChange} required />
        </label>
        <label>
          {t('Place')}:
          <input type="text" name="place" value={formData.place} onChange={handleChange} required />
        </label>
        <label>
          {t('Price')}:
          <input type="number" name="price" value={formData.price} onChange={handleChange} required />
        </label>
        <label>
          {t('Discount')}:
          <input type="number" name="discount" value={formData.discount} onChange={handleChange} />
        </label>
        <label>
          {t('Quantity of Spaces')}:
          <input type="number" name="qty_spaces" value={formData.qty_spaces} onChange={handleChange} required />
        </label>
        <label>
          {t('Link')}:
          <input type="url" name="link" value={formData.link} onChange={handleChange} />
        </label>
        <input type="hidden" name="fk_origin_id" value={formData.fk_origin_id} />
        <label>
          {t('Event Type')}:
          <select
            name="fk_event_type_id"
            value={formData.fk_event_type_id}
            onChange={handleChange}
            required
          >
            <option value="">{t('Select an event type')}</option>
            {eventTypes.map((type) => (
              <option key={type.event_type_id} value={type.event_type_id}>
                {type.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          {t('Status')}:
          <select
            name="status"
            value={formData.status}
            onChange={handleStatusChange}
            required
          >
            <option value="">{t('Select a status')}</option>
            <option value="activo">{t('Active')}</option>
            <option value="cancelado">{t('Cancelled')}</option>
            <option value="finalizado">{t('Finished')}</option>
          </select>
        </label>
        <label>
          {t('Photos')}:
          <input type="file" accept="image/*" multiple onChange={handleFileChange} />
        </label>
        <div className={styles.galleryContainer}>
          {formData.images && formData.images.map((image, index) => (
            <div key={index} className={styles.imageWrapper}>
              <img
                src={image.file ? URL.createObjectURL(image.file) : image.ref_firestore}
                alt={`event-${index}`}
                className={styles.eventImage}
              />
              <div className={styles.coverCheckbox}>
                <input
                  type="checkbox"
                  checked={index === coverImageIndex}
                  onChange={() => setCoverImageIndex(index)}
                />
                <label>{t('Cover')}</label>
              </div>
              {!isEditing && (
                <button
                  type="button"
                  className={styles.removeImageButton}
                  onClick={() => {
                    const updatedImages = formData.images.filter((_, i) => i !== index);
                    setFormData({ ...formData, images: updatedImages });
                  }}
                >
                  {t('Remove')}
                </button>
              )}
              {isEditing && (
                <button
                  type="button"
                  className={styles.removeImageButton}
                  onClick={() => openModal(image)}
                >
                  {t('Remove')}
                </button>
              )}
            </div>
          ))}
        </div>
        <button type="submit">{isEditing ? t('Update') : t('Register')}</button>
      </form>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel={t('Confirm Delete Image')}
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <h2>{t('Confirm Deletion')}</h2>
        <p>{t('Are you sure you want to delete this image?')}</p>
        <button onClick={handleDeleteImage} className={styles.confirmButton}>{t('Delete')}</button>
        <button onClick={closeModal} className={styles.cancelButton}>{t('Cancel')}</button>
      </Modal>
    </div>
  );
};

export default RegistrarEvento;